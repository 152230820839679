import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ComponentInit } from '../../core/classes/component-init.class';
import { formatDate } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ScrollLocations } from '../../core/globals/scroll-locations';

import { AccountService } from '../../core/services/account.service';
import { VacationRequestService } from '../shared/vacation-request.service';
import { VacationRequest } from '../shared/vacation-request.model';
import { Organization } from '../../core/models/organization.model';
import { OrganizationService } from '../../core/services/organization.service';

import { MatDialog } from '@angular/material';
import { OkDialogComponent } from '../../shared/ok-dialog/ok-dialog.component';

import { faPlane, faTag, faStepForward, faStepBackward, faArrowsAltH, faBan } from '@fortawesome/free-solid-svg-icons';
import { faClock, faBuilding } from '@fortawesome/free-regular-svg-icons';

@Component({
	selector: 'app-vacation-request-new',
	templateUrl: './vacation-request-new.component.html',
	styleUrls: ['./vacation-request-new.component.css']
})
export class VacationRequestNewComponent extends ComponentInit {
	faPlane = faPlane;
	faTag = faTag;
	faStepForward = faStepForward;
	faStepBackward = faStepBackward;
	faClock = faClock;
	faArrowsAltH = faArrowsAltH;
	faBan = faBan;
	faBuilding = faBuilding;

	form: FormGroup;
	vacationRequest:VacationRequest;

	loading: boolean = false;
	submitting: boolean = false;

	orga_id: number = null;
	company_id: number = null;
	orgas:Organization[];
	orga:Organization;

	overtime: number = null;
	vacation_left: number = null;
	amount_initialised = false;
	amount_type: string = 'Tage';
	conflicts: string[] = [];
	locks: object[] = [];

	date = new Date();

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private vrs: VacationRequestService,
		private as: AccountService,
		private os: OrganizationService,
		protected router: Router,
		protected scrollLocations: ScrollLocations,
		private dialog: MatDialog,
		private location: Location,
		@Inject(LOCALE_ID) private locale:string,
	) {
		super(router, scrollLocations);
		this.form = this.fb.group({
			type: ['vacation', Validators.required],
			start_date: ['', Validators.required],
			start_time: [''],
			end_date: ['', Validators.required],
			end_time: [''],
			amount: [null],
			note: ['']
		})
	}

	init(){
		this.orga_id = Number(this.route.snapshot.paramMap.get('orga'));
		this.company_id = Number(this.route.snapshot.queryParams.company);

		// Get Overtime and vacation left
		this.as.getCurrentAccount().subscribe(res => {
	    	if(!res) return;

	        let overtime_entry = res.summary.overtime.find(val => {
				return val.company.organization == this.orga_id && (!this.company_id || val.company.id == this.company_id)
			});
			if(overtime_entry) this.overtime = overtime_entry.value;

			let vacation_entry = res.summary.vacation_left.find(val => {
				return val.company.organization == this.orga_id && (!this.company_id || val.company.id == this.company_id)
			});
			if(vacation_entry) this.vacation_left = vacation_entry.value;
	    });

		// Get Organizations
		this.os.getOrganizationsOfCurrentAccountObservable().subscribe(res => {
			if(!res) return;
			this.orgas = res;
			this.orga = this.orgas.find(orga => { return orga.id == this.orga_id });
	    });

		// Type Change
		this.form.get('type').valueChanges.subscribe(val => {
			if(val == 'free' || val == 'not_available'){
				this.form.get('start_time').setValidators([Validators.required]);
				this.form.get('end_time').setValidators([Validators.required]);
			} else {
				this.form.get('start_time').clearValidators();
				this.form.get('end_time').clearValidators();
			}
			this.form.get('start_time').updateValueAndValidity();
			this.form.get('end_time').updateValueAndValidity();

			if(!this.form.valid){
				this.amount_initialised = false;
				this.form.patchValue({amount: null});
			}
	    });

		// Start Change
		this.form.get('start_date').valueChanges.subscribe(val => {
            if(this.form.value.type == 'free' && val && !this.form.value.end_date){
                this.form.patchValue({end_date: val});
            }

             // Make sure start <= end
            if(val > this.form.get('end_date').value) this.form.patchValue({end_date: val});
		});

		// // End Change
		// this.form.get('end_date').valueChanges.subscribe(val => {

		// });

		// this.form.get('amount').valueChanges.subscribe(val => {
		// 	console.log(val);
		// });

	}

	getDateStrings(){
		let date_from = formatDate(this.form.value.start_date, 'yyyy-MM-dd', this.locale);
		let date_to = formatDate(this.form.value.end_date, 'yyyy-MM-dd', this.locale);

		if(this.form.value.type == 'free' || this.form.value.type == 'not_available'){
			if(this.form.value.start_time) date_from += ' ' + this.form.value.start_time;
			if(this.form.value.end_time) date_to += ' ' + this.form.value.end_time;
		}

		return [date_from, date_to];
	}

	onFormChange(){
		if(!this.form.valid) return;

		// Reset Conflicts, Locks
		this.conflicts = [];
		this.locks = [];

		let date_strings = this.getDateStrings();

		if(date_strings[0] > date_strings[1]) return;

        // check for min date
        let min_date = new Date();
        min_date.setFullYear(min_date.getFullYear() - 1);
        if(date_strings[0] < formatDate(min_date, 'yyyy-MM-dd', this.locale)) return;

		this.loading = true;

		this.vrs.getInfosInPeriodOfType(this.orga_id, date_strings[0], date_strings[1], this.form.value.type, this.company_id).subscribe((res: any) => {
			this.loading = false;
			if(!res) return;

			this.amount_initialised = true;
			this.form.patchValue({amount: res.amount});

			// Change amount type
			if(this.form.value.type == 'free' || this.form.value.type == 'not_available')
				this.amount_type = 'Stunde' + (res.amount != 1 ? 'n' : '');
			else this.amount_type = 'Tag' + (res.amount != 1 ? 'e' : '');

			// Conflicts, Locks, Overlaps
			this.conflicts = res.conflicts;
			this.locks = res.locks;
		});
	}

	onSubmit(){
		if(!this.form.valid)
			return this.dialog.open(OkDialogComponent, {data: 'Bitte fülle das Formular korrekt aus.'});

		let date_strings = this.getDateStrings();

		// Date Check
		if(date_strings[0] > date_strings[1])
			return this.dialog.open(OkDialogComponent, {data: 'Das Ende muss größer als der Beginn sein.'});

		// Amount Check
		/* if(!this.form.value.amount)
			return alert('Es muss eine Anzahl an Tagen/Stunden angegeben werden.'); */

		this.submitting = true;

		let data = {
			type: this.form.value.type,
			date_from: date_strings[0],
			date_to: date_strings[1],
			amount: this.form.value.amount,
			note: this.form.value.note,
			company: this.company_id
		}

		this.vrs.create(this.orga_id, data).subscribe(
			res => { // Success
				this.submitting = false;

				if(!res || !res.id) return this.dialog.open(OkDialogComponent, {data: 'Es ist ein Fehler aufgetreten.'});

				this.router.navigate(['/vacation-requests/']);

			}, err => { // Error
				this.submitting = false;
                if(err && err.error && err.error.text)
				    this.dialog.open(OkDialogComponent, {data:err.error.text});
			}
		);
	}
}
