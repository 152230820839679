var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ComponentInit } from '../../core/classes/component-init.class';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { VacationRequestService } from '../shared/vacation-request.service';
import { OrganizationService } from '../../core/services/organization.service';
import { faTag, faBan, faPlus, faCheck, faTimes, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt, faBuilding } from '@fortawesome/free-regular-svg-icons';
var VacationRequestDetailComponent = /** @class */ (function (_super) {
    __extends(VacationRequestDetailComponent, _super);
    function VacationRequestDetailComponent(route, vrs, os, router, scrollLocations, location) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.route = route;
        _this.vrs = vrs;
        _this.os = os;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.location = location;
        _this.faTag = faTag;
        _this.faCalendarAlt = faCalendarAlt;
        _this.faBuilding = faBuilding;
        _this.faBan = faBan;
        _this.faPlus = faPlus;
        _this.faCheck = faCheck;
        _this.faTimes = faTimes;
        _this.faQuestion = faQuestion;
        _this.submitting = false;
        return _this;
    }
    VacationRequestDetailComponent.prototype.init = function () {
        var _this = this;
        // Get Entry
        this.route.data.subscribe(function (data) {
            if (data.resolved.model)
                _this.entry = data.resolved.model;
            data.resolved.updates.subscribe(function (res) {
                if (res)
                    _this.entry = res;
            });
        });
        // Get Organizations
        this.os.getOrganizationsOfCurrentAccountObservable().subscribe(function (res) {
            if (res)
                _this.orgas = res;
            if (_this.entry)
                _this.orga = _this.orgas.find(function (orga) { return orga.id == _this.entry.organization; });
        });
    };
    VacationRequestDetailComponent.prototype.instanceIcon = function (instance) {
        if (instance.state == '1')
            return faCheck;
        else if (instance.state == '2')
            return faTimes;
        else
            return faQuestion;
    };
    VacationRequestDetailComponent.prototype.instanceColor = function (instance) {
        if (instance.state == '1')
            return 'gr_fg';
        else if (instance.state == '2')
            return 're_fg';
        else
            return 'ye_fg';
    };
    VacationRequestDetailComponent.prototype.delete = function () {
        var _this = this;
        if (window.confirm("Willst du wirklich diesen Antrag löschen?")) {
            this.submitting = true;
            this.vrs.delete(this.entry.organization, this.entry.id).subscribe(function (res) {
                _this.submitting = false;
                _this.router.navigate(['/vacation-requests/']);
            });
            ;
        }
    };
    return VacationRequestDetailComponent;
}(ComponentInit));
export { VacationRequestDetailComponent };
