import { Component, OnInit } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';

import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ScrollLocations } from '../../core/globals/scroll-locations';

import { VacationRequestService } from '../shared/vacation-request.service';
import { VacationRequest } from '../shared/vacation-request.model';
import { Organization } from '../../core/models/organization.model';
import { OrganizationService } from '../../core/services/organization.service';

import { faTag, faBan, faPlus, faCheck, faTimes, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt, faBuilding } from '@fortawesome/free-regular-svg-icons';

@Component({
	selector: 'app-vacation-request-detail',
	templateUrl: './vacation-request-detail.component.html',
	styleUrls: ['./vacation-request-detail.component.css']
})
export class VacationRequestDetailComponent extends ComponentInit {
	faTag = faTag;
	faCalendarAlt = faCalendarAlt;
	faBuilding = faBuilding;
	faBan = faBan;
	faPlus = faPlus;
	faCheck = faCheck;
	faTimes = faTimes;
	faQuestion = faQuestion;

	entry:VacationRequest;
	orgas:Organization[];
	orga:Organization;

	submitting:boolean = false;

	constructor(
		private route: ActivatedRoute,
		private vrs: VacationRequestService,
		private os: OrganizationService,
		protected router: Router,
		protected scrollLocations: ScrollLocations,
		private location: Location
	) { super(router, scrollLocations, route); }

	init() {
		// Get Entry
		this.route.data.subscribe(data => {
			if(data.resolved.model) this.entry = data.resolved.model;
			data.resolved.updates.subscribe(res => {
				if(res) this.entry = res;
			});
		});

		// Get Organizations
		this.os.getOrganizationsOfCurrentAccountObservable().subscribe(res => {
			if(res) this.orgas = res;
			if(this.entry) this.orga = this.orgas.find(orga => { return orga.id == this.entry.organization });
		});
	}

	instanceIcon(instance){
		if(instance.state == '1') return faCheck;
		else if(instance.state == '2') return faTimes;
		else return faQuestion;
	}

	instanceColor(instance){
		if(instance.state == '1') return 'gr_fg';
		else if(instance.state == '2') return 're_fg';
		else return 'ye_fg';
	}

	delete(){
		if(window.confirm("Willst du wirklich diesen Antrag löschen?")){
			this.submitting = true;

			this.vrs.delete(this.entry.organization, this.entry.id).subscribe((res: any) => {
				this.submitting = false;
				this.router.navigate(['/vacation-requests/']);
			});;
		}
	}
}
