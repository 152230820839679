var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
// import { AbstractCachableService } from '../classes/abstract-cachable-service.class';
import { formatDate } from '@angular/common';
import { createDateFromDatetime } from 'src/app/core/utils/date-utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var WorkService = /** @class */ (function (_super) {
    __extends(WorkService, _super);
    function WorkService(http, locale) {
        var _this = _super.call(this, http, 'workSchedules', false, 'month') || this;
        _this.http = http;
        _this.locale = locale;
        // Current work schedule entry
        var workSchedule = localStorage.getItem('currWorkSchedule');
        var workScheduleEntry = localStorage.getItem('currWorkScheduleEntry');
        _this.currWorkSchedule = new BehaviorSubject(workSchedule ? JSON.parse(workSchedule) : null);
        _this.currWorkScheduleEntry = new BehaviorSubject(workScheduleEntry ? JSON.parse(workScheduleEntry) : null);
        _this.working = new BehaviorSubject(!!workSchedule);
        _this.startCurrentEntryUpdater();
        _this.getCurrent();
        return _this;
    }
    WorkService.prototype.getByOrganizationAndId = function (orga, id) {
        // Get Data
        var data = this.findModelByOrganizationAndId(orga, id);
        var http = this.http.get('/v1/organizations/' + orga + '/work-schedule/' + id, { params: {
                date_out: this.preselectedDateOut ? encodeURIComponent(formatDate(this.preselectedDateOut, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale)) : ''
            } });
        return this.cachedRequest(data, http);
    };
    /**
     * Listens to schedule changes and updates current entry
     */
    WorkService.prototype.startCurrentEntryUpdater = function () {
        var _this = this;
        this.currWorkSchedule.subscribe(function (schedule) {
            if (!schedule)
                return;
            var runningEntry = schedule.entries ? schedule.entries.find(function (entry) { return !entry.end; }) : null;
            _this.setCurrWorkScheduleEntry(runningEntry || null);
        });
    };
    /**
     * Create new work schedule entry
     * @param data
     */
    WorkService.prototype.createWorkScheduleEntry = function (data) {
        // sanizize data
        if (data.start) {
            // string -> Date
            if (typeof data.start === 'string')
                data.start = createDateFromDatetime(data.start);
            data.start = formatDate(data.start, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale);
        }
        if (data.end) {
            // string -> Date
            if (typeof data.end === 'string')
                data.end = createDateFromDatetime(data.end);
            data.end = formatDate(data.end, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale);
        }
        return this.http.post("/v1/work-schedule-entries", data);
    };
    /**
     * updates current works schedule entry
     * @param data
     */
    WorkService.prototype.updateCurrentWorkScheduleEntry = function (data) {
        var currEntry = this.currWorkScheduleEntry.value;
        return this.updateWorkScheduleEntry(currEntry.organization, currEntry.id, data);
    };
    /**
     * Updates work schedules entries
     * @param entries
     */
    WorkService.prototype.updateCurrentWorkEntries = function (entries) {
        var currWorkSchedule = this.currWorkSchedule.value;
        if (!currWorkSchedule)
            return;
        var _loop_1 = function (entry) {
            var index = currWorkSchedule.entries.findIndex(function (e) { return e.id === entry.id; });
            currWorkSchedule[index] = entry;
        };
        for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
            var entry = entries_1[_i];
            _loop_1(entry);
        }
        this.currWorkSchedule.next(currWorkSchedule);
    };
    /**
     * patches work schedule entry
     * @param orga
     * @param id
     * @param data
     */
    WorkService.prototype.updateWorkScheduleEntry = function (orga, id, data) {
        var _this = this;
        // sanizize data
        if (data.start) {
            // string -> Date
            if (typeof data.start === 'string')
                data.start = createDateFromDatetime(data.start);
            data.start = formatDate(data.start, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale);
        }
        if (data.end) {
            // string -> Date
            if (typeof data.end === 'string')
                data.end = createDateFromDatetime(data.end);
            data.end = formatDate(data.end, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale);
        }
        return this.http.patch("/v1/organizations/" + orga + "/work-schedule-entries/" + id, data)
            .pipe(tap(function (entry) { return _this.updateCurrentWorkEntries([entry]); }));
    };
    /**
     * deletes work schedule entry
     * @param orga
     * @param id
     */
    WorkService.prototype.deleteWorkScheduleEntry = function (orga, id) {
        var _this = this;
        return this.http.delete("/v1/organizations/" + orga + "/work-schedule-entries/" + id).pipe(tap(function (res) {
            // remove entry from currWorkSchedule
            var currWorkSchedule = _this.currWorkSchedule.value;
            if (currWorkSchedule) {
                currWorkSchedule.entries = currWorkSchedule.entries.filter(function (e) { return e.id !== id; });
                _this.currWorkSchedule.next(currWorkSchedule);
            }
        }));
    };
    WorkService.prototype.clear = function () {
        this.currWorkSchedule.next(null);
        this.currWorkScheduleEntry.next(null);
        this.working.next(false);
        localStorage.removeItem('currWorkSchedule');
        localStorage.removeItem('currWorkScheduleEntry');
    };
    /**
    * Returns current work schedule.
    */
    WorkService.prototype.getCurrent = function () {
        var _this = this;
        this.http.get('/v1/work-schedule/current', { params: {
                date_out: this.preselectedDateOut ? encodeURIComponent(formatDate(this.preselectedDateOut, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale)) : ''
            } })
            .subscribe(function (res) {
            _this.setCurrWorkSchedule(res);
        });
        return this.currWorkSchedule.asObservable();
    };
    /**
     * Returns all work schedule entries of month (yyyy-mm)
     */
    WorkService.prototype.getAllByMonth = function (year, month) {
        // Calculate date_from
        var date_from = new Date();
        date_from.setDate(1);
        date_from.setFullYear(year);
        date_from.setMonth(month - 1);
        // Calculate date_to
        var date_to = new Date(date_from.getFullYear(), date_from.getMonth() + 1, 0);
        var params = {
            date_from: this.dateToString(date_from, 'yyyy-MM-dd'),
            date_to: this.dateToString(date_to, 'yyyy-MM-dd')
        };
        // build clear data
        var clearData = {
            type: 'period',
            data: { from: params.date_from, to: params.date_to, property: 'month' }
        };
        // get cached data
        var data = this.models;
        var http = this.http.get('/v1/work-schedules', { params: params });
        return this.cachedRequest(data, http, clearData);
    };
    /**
    * Ends current Work Schedule
    * @param comment comment as string
    */
    WorkService.prototype.endCurrWorkSchedule = function (comment, date, entries, breakSuggestion, workplace) {
        /* let payload = {
            verify: 'app',
            date: formatDate(date, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale),
            comment,
            entries,
        } */
        var _this = this;
        var schedule = this.currWorkSchedule.getValue();
        if (!schedule) {
            console.error('User must have a work schedule');
            return;
        }
        return this.endWorkSchedule(comment, date, entries, schedule, breakSuggestion, workplace).pipe(tap(function () { return _this.clear(); }, function (error) { return console.log('error: ', error); }));
        /* return this.http.post<WorkSchedule>('/v1/organizations/'+schedule.organization+'/work-schedule/'+schedule.id+'/end', payload).pipe(
            tap(
                () => this.clear(),
                error => console.log('error: ', error)
            )
        ) */
    };
    /**
    * Ends Work Schedule
    * @param comment comment as string
    */
    WorkService.prototype.endWorkSchedule = function (comment, date, entries, schedule, breakSuggestion, workplace) {
        var payload = {
            verify: 'app',
            date: formatDate(date, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale),
            comment: comment,
            entries: entries,
            break_suggestion: breakSuggestion,
            workplace: workplace
        };
        return this.http.post('/v1/organizations/' + schedule.organization + '/work-schedule/' + schedule.id + '/end', payload);
    };
    /**
    * starts new work schedule
    * @param oid
    * @param comment
    */
    WorkService.prototype.startWorkSchedule = function (oid, comment, date, date_ref, entry, company, workplace) {
        var _this = this;
        if (company === void 0) { company = null; }
        if (workplace === void 0) { workplace = null; }
        var payload = {
            verify: 'app',
            date: formatDate(date, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale),
            date_ref: formatDate(date_ref, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale),
            comment: comment,
            workplace: workplace,
            entry: entry,
            company: company
        };
        /* if(this.currWorkSchedule.getValue()) {
            console.error('User has work scheduled');
            return;
        } */
        return this.http.post('/v1/organizations/' + oid + '/work-schedule', payload).pipe(tap(function (res) {
            if (res)
                _this.setCurrWorkSchedule(res);
        }));
    };
    /**
    * caches work schedule
    * @param workSchedule
    */
    WorkService.prototype.setCurrWorkSchedule = function (workSchedule) {
        localStorage.setItem('currWorkSchedule', JSON.stringify(workSchedule));
        this.currWorkSchedule.next(workSchedule);
        this.working.next(workSchedule ? true : false);
    };
    /**
     * caches work schedule entry
     * @param entry
     */
    WorkService.prototype.setCurrWorkScheduleEntry = function (entry) {
        localStorage.setItem('currWorkScheduleEntry', JSON.stringify(entry));
        this.currWorkScheduleEntry.next(entry);
    };
    WorkService.prototype.createBreak = function (orga_id, ws_id, data) {
        var _this = this;
        var payload = {
            start: formatDate(data.start, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale),
            end: data.end ? formatDate(data.end, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale) : null,
            automatic: data.automatic ? 1 : 0,
            ws: ws_id
        };
        return this.http.post('/v1/organizations/' + orga_id + '/work-schedule-break', payload).pipe(tap(function (res) {
            // update current work Schedule (add break)
            if (res && !payload.automatic && _this.currWorkSchedule && _this.currWorkSchedule.value) {
                var currWorkSchedule = _this.currWorkSchedule.value;
                if (currWorkSchedule.id == ws_id) {
                    currWorkSchedule.breaks.push(res);
                    _this.setCurrWorkSchedule(currWorkSchedule);
                }
            }
        }));
    };
    WorkService.prototype.editBreak = function (orga_id, break_id, data) {
        var _this = this;
        var payload = {
            start: formatDate(data.start, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale),
            end: data.end ? formatDate(data.end, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale) : null
        };
        return this.http.patch('/v1/organizations/' + orga_id + '/work-schedule-break/' + break_id, payload).pipe(tap(function (res) {
            // update current work Schedule (adjust break)
            if (res && _this.currWorkSchedule && _this.currWorkSchedule.value) {
                var currWorkSchedule = _this.currWorkSchedule.value;
                console.log(currWorkSchedule);
                if (currWorkSchedule.id == res.ws) {
                    currWorkSchedule.breaks = currWorkSchedule.breaks.map(function (b) { return b.id == res.id ? res : b; });
                    console.log(currWorkSchedule);
                    _this.setCurrWorkSchedule(currWorkSchedule);
                }
            }
        }));
    };
    /**
     * deletes break entry
     * @param orga
     * @param id
     */
    WorkService.prototype.deleteBreak = function (orga, id) {
        var _this = this;
        return this.http.delete("/v1/organizations/" + orga + "/work-schedule-break/" + id).pipe(tap(function () {
            if (_this.currWorkSchedule && _this.currWorkSchedule.value) {
                // remove entry from currWorkSchedule
                var currWorkSchedule = _this.currWorkSchedule.value;
                if (currWorkSchedule) {
                    currWorkSchedule.breaks = currWorkSchedule.breaks.filter(function (e) { return e.id !== id; });
                    _this.currWorkSchedule.next(currWorkSchedule);
                }
            }
        }));
    };
    Object.defineProperty(WorkService.prototype, "currentObservable", {
        /**
        * Returns current work schedule observable.
        * does not fetch data from server
        */
        get: function () {
            return this.currWorkSchedule.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkService.prototype, "workingObservable", {
        /**
        * Returns observable that indicates if the user is working.
        * does not fetch data from server
        */
        get: function () {
            return this.working.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkService.prototype, "isWorking", {
        /**
        * indicates if the user is working.
        * does not fetch data from server
        */
        get: function () {
            return this.working.getValue();
        },
        enumerable: true,
        configurable: true
    });
    WorkService.ngInjectableDef = i0.defineInjectable({ factory: function WorkService_Factory() { return new WorkService(i0.inject(i1.HttpClient), i0.inject(i0.LOCALE_ID)); }, token: WorkService, providedIn: "root" });
    return WorkService;
}(CachableModelService));
export { WorkService };
