var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ComponentInit } from '../../core/classes/component-init.class';
import { Router, ActivatedRoute } from '@angular/router';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { VacationRequestService } from '../shared/vacation-request.service';
import { OrganizationService } from '../../core/services/organization.service';
import { faCheck, faTimes, faQuestion, faPlus } from '@fortawesome/free-solid-svg-icons';
;
var VacationRequestListComponent = /** @class */ (function (_super) {
    __extends(VacationRequestListComponent, _super);
    function VacationRequestListComponent(route, vrs, os, router, scrollLocations) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.route = route;
        _this.vrs = vrs;
        _this.os = os;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.faCheck = faCheck;
        _this.faTimes = faTimes;
        _this.faQuestion = faQuestion;
        _this.faPlus = faPlus;
        _this.selected = 0;
        _this.data = [];
        _this.columnsToDisplay = ['type', 'period', 'duration'];
        return _this;
    }
    VacationRequestListComponent.prototype.init = function () {
        var _this = this;
        // Get Organizations
        this.os.getOrganizationsOfCurrentAccountObservable().subscribe(function (res) {
            if (!res)
                return;
            res.forEach(function (orga) {
                var orga_index = _this.data.findIndex(function (element) { return element['organizationId'] == orga.id && element['companyId'] == orga.company; });
                if (orga_index > -1) {
                    _this.data[orga_index].companyTitle = orga.title;
                }
                else {
                    _this.data.push({
                        organizationId: orga.id,
                        companyId: orga.company,
                        companyTitle: orga.title,
                        entries: []
                    });
                }
            });
            _this.selected = _this.tabSelected;
        });
        this.getEntries();
    };
    VacationRequestListComponent.prototype.reload = function () {
        this.getEntries();
    };
    VacationRequestListComponent.prototype.getEntries = function () {
        var _this = this;
        // Get vacation request entries
        this.vrs.getVacationRequests().subscribe(function (entries) {
            console.log(entries);
            // Reset entries
            _this.data.forEach(function (value, key) {
                _this.data[key].entries = [];
            });
            // :: Data Check
            if (!entries)
                return;
            entries.forEach(function (entry) {
                // Check if orga entry already exists
                var orga_index = _this.data.findIndex(function (element) { return element['organizationId'] == entry.organization && element['companyId'] == entry.company; });
                if (orga_index == -1) {
                    orga_index = -1 + _this.data.push({
                        companyTitle: '',
                        organizationId: entry.organization,
                        companyId: entry.company,
                        entries: []
                    });
                }
                _this.data[orga_index].entries.push(entry);
            });
            console.log(_this.data);
        });
    };
    VacationRequestListComponent.prototype.typeTitle = function (entry) {
        return entry.type;
    };
    VacationRequestListComponent.prototype.durationTitle = function (entry) {
        return 'Tage/Stunden';
    };
    VacationRequestListComponent.prototype.statusTitle = function (entry) {
        return entry.status;
    };
    VacationRequestListComponent.prototype.statusIcon = function (entry) {
        if (entry.status == 2)
            return this.faTimes;
        else if (entry.status == 1)
            return this.faCheck;
        else
            return this.faQuestion;
    };
    VacationRequestListComponent.prototype.statusColor = function (entry) {
        if (entry.status == 2)
            return 're_fg';
        else if (entry.status == 1)
            return 'gr_fg';
        else
            return 'ye_fg';
    };
    VacationRequestListComponent.prototype.newRequest = function (orga, company_id) {
        this.router.navigate(['/vacation-request-new/' + orga], { queryParams: { company: company_id } });
    };
    VacationRequestListComponent.prototype.routeRequest = function (orga_id, entry_id) {
        this.router.navigate(['/vacation-request/' + orga_id + '/' + entry_id]);
    };
    return VacationRequestListComponent;
}(ComponentInit));
export { VacationRequestListComponent };
