import { Component, OnInit, ViewChildren } from '@angular/core';
import { Location } from '@angular/common';
import { SwPush } from '@angular/service-worker';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart, NavigationCancel } from '@angular/router';

import {filter, map, mergeMap, switchMap, distinct} from 'rxjs/operators';

// icons
import {
  faBars,
  faHome,
  faShareAlt,
  faBriefcase,
  faUsers,
  faFile,
  faCog,
  faArrowLeft,
  faHandshake,
  faTruck,
  faInfo,
  faRocket,
  faClock,
  faCheckSquare,
  faPlane,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCalendarAlt,
  faCalendarPlus,
  faCalendarCheck
} from '@fortawesome/free-regular-svg-icons';

import { DeviceService } from '../core/services/device.service';
import { NotificationService } from '../core/services/notification.service';
import { TitleService } from '../core/services/title.service';
import { AccountService } from '../core/services/account.service';
import { Account } from '../core/models/account.model';
import { OrganizationService } from '../core/services/organization.service';
import { DashboardService } from '../core/services/dashboard.service';

import { ScrollLocations } from '../core/globals/scroll-locations';
import * as globals from '../core/globals/globals';

import { environment } from '../../environments/environment';

// var tabsSelected:Object = {};

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit{

  title: string;
  back: boolean = false;  // renders a back button
  close: boolean = false;  // renders a close button
  online: boolean;
  loading: boolean = false;

  account:Account;

  showVacationRequestItem: boolean = false;


  // icons
  faBars = faBars;
  faHome = faHome;
  faCalendarAlt = faCalendarAlt;
  faCalendarPlus = faCalendarPlus;
  faShareAlt = faShareAlt;
  faBriefcase = faBriefcase;
  faUsers = faUsers;
  faFile = faFile;
  faCog = faCog;
  faArrowLeft = faArrowLeft;
  faHandshake = faHandshake;
  faTruck = faTruck;
  faInfo = faInfo;
  faRocket = faRocket;
  faClock = faClock;
  faCheckSquare = faCheckSquare;
  faPlane = faPlane;
  faTimes = faTimes;
  faCalendarCheck = faCalendarCheck;
  environment = environment;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private titleService: TitleService,
    private ns: NotificationService,
    private swPush: SwPush,
    public device: DeviceService,
    private accountService: AccountService,
    private organizationService: OrganizationService,
    public ds: DashboardService,
    protected scrollLocations: ScrollLocations
  ) {
    if(!ns.isSubscribed && !this.ns.changedSubscription()){
      this.swPush.requestSubscription({
        serverPublicKey: this.ns.getVAPID
      }).then(sub => {
        // subscribe
        this.ns.subscribe(sub).subscribe();
      })
      .catch(err => console.error("Could not subscribe to notifications", err))
    }

    // retrieve some data
    this.accountService.getCurrentAccount().subscribe(res => {
      if(!res) return;

      this.account = res;
    });
    this.organizationService.getAllForCurrentAccount().subscribe(() => {
        console.log('got orgas');
    });
  }

  /**
   * Whether menu is toggable
   */
  get isMenuToggable() {

    return !this.back && !this.close;
  }

  ngOnInit(){
    // get current url
    let url = this.router.routerState.snapshot.url;
    url = url.split("?")[0]; // remove query params
    // url = url.slice(1, url.length); // remove trailing '/'

    this.organizationService.getOrganizationsOfCurrentAccountObservable().subscribe(res => {
        if(!res) return;
        // check if we can show vacation requests item in menu
        this.showVacationRequestItem = !!res.filter(orga => orga.vacation_request_active === true || (orga.vacation_request_active && orga.vacation_request_active.length)).length;
    });

    // subscribe for title changes
    this.ds.titleObservable.subscribe(title => {
      this.title = title;
    });
    // subscribe for online changes
    this.ds.onlineObservable.subscribe(online => {
      this.online = online;
    });
    // subscribe for loading changes
    this.ds.loadingObservable.subscribe(loading => {
      this.loading = loading;
    });

    this.activatedRoute.firstChild.data.subscribe(data => {
      this.title = data.title || "";
      this.back = data.back || false;
      this.close = data.close || false;
    });

    // subscribe for future route data changes
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      switchMap((route) => route.data),
      distinct()
    )
    .subscribe((event) => {
      // refresh organizations
      this.organizationService.getAllForCurrentAccount();

      // toggle title if desired
      if(event['title'])
        this.title = event['title'];
      else
        this.title = this.titleService.getTitle();

      // toggle nav button if desired
      if(event['back'])
        this.back = event['back'];
      else
        this.back = false;
    });

    // Handle tab param
    if(this.activatedRoute.snapshot.queryParams.tab)
      globals.selectedTabs[url] = this.activatedRoute.snapshot.queryParams.tab;

    this.router.events.subscribe((event) => {
      let scrollContainer = document.querySelector('.filter_list');
      if(!scrollContainer) scrollContainer = document.querySelector('.mat-drawer-content');

      // Handle stuff when navigation starts
      if(event instanceof NavigationStart){
        let url_old = this.router.routerState.snapshot.url;
        url_old = url_old.split("?")[0]; // remove query params

        // Add scroll position to positions
        if(scrollContainer)
          this.scrollLocations.positions[url_old] = scrollContainer.scrollTop;

        // Add current active tab to tabsSelected
        if(document.querySelector('.mat-drawer-content mat-tab-group'))
          globals.selectedTabs[url_old] = document.querySelector('.mat-drawer-content mat-tab-group').getAttribute('ng-reflect-selected-index');

        // Reset active tab to 0 when trigger is not "popstate"
        if(event.navigationTrigger != 'popstate'){
          let event_url = event.url;
          event_url = event_url.split("?")[0]; // remove query params
          // event_url = event_url.slice(1, event_url.length); // remove trailing '/'
          globals.selectedTabs[event_url] = 0;
        }


      // Handle stuff when navigation ends
      } else if(event instanceof NavigationEnd){
        // Wait for render
        setTimeout(() => {
          // Set Scroll Position
          if(scrollContainer){
            let event_url = event.url;
            event_url = event_url.split("?")[0]; // remove query params

            let scrollToY = this.scrollLocations.positions[event_url] || 0;
            if(typeof scrollContainer.scrollTo === "function"){
                scrollContainer.scrollTo(0, scrollToY);
            } else {
                scrollContainer.scrollTop = scrollToY;
            }
          }
        }, 5);

      }
    });
  }

  /**
   * Navigates back
   */
  onBackClick(){
    if(window.history.length > 1)
      this.location.back();
    else
      this.router.navigateByUrl('/');
  }
}
