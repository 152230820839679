var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ComponentInit } from '../../core/classes/component-init.class';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { AccountService } from '../../core/services/account.service';
import { VacationRequestService } from '../shared/vacation-request.service';
import { OrganizationService } from '../../core/services/organization.service';
import { MatDialog } from '@angular/material';
import { OkDialogComponent } from '../../shared/ok-dialog/ok-dialog.component';
import { faPlane, faTag, faStepForward, faStepBackward, faArrowsAltH, faBan } from '@fortawesome/free-solid-svg-icons';
import { faClock, faBuilding } from '@fortawesome/free-regular-svg-icons';
var VacationRequestNewComponent = /** @class */ (function (_super) {
    __extends(VacationRequestNewComponent, _super);
    function VacationRequestNewComponent(fb, route, vrs, as, os, router, scrollLocations, dialog, location, locale) {
        var _this = _super.call(this, router, scrollLocations) || this;
        _this.fb = fb;
        _this.route = route;
        _this.vrs = vrs;
        _this.as = as;
        _this.os = os;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.dialog = dialog;
        _this.location = location;
        _this.locale = locale;
        _this.faPlane = faPlane;
        _this.faTag = faTag;
        _this.faStepForward = faStepForward;
        _this.faStepBackward = faStepBackward;
        _this.faClock = faClock;
        _this.faArrowsAltH = faArrowsAltH;
        _this.faBan = faBan;
        _this.faBuilding = faBuilding;
        _this.loading = false;
        _this.submitting = false;
        _this.orga_id = null;
        _this.company_id = null;
        _this.overtime = null;
        _this.vacation_left = null;
        _this.amount_initialised = false;
        _this.amount_type = 'Tage';
        _this.conflicts = [];
        _this.locks = [];
        _this.date = new Date();
        _this.form = _this.fb.group({
            type: ['vacation', Validators.required],
            start_date: ['', Validators.required],
            start_time: [''],
            end_date: ['', Validators.required],
            end_time: [''],
            amount: [null],
            note: ['']
        });
        return _this;
    }
    VacationRequestNewComponent.prototype.init = function () {
        var _this = this;
        this.orga_id = Number(this.route.snapshot.paramMap.get('orga'));
        this.company_id = Number(this.route.snapshot.queryParams.company);
        // Get Overtime and vacation left
        this.as.getCurrentAccount().subscribe(function (res) {
            if (!res)
                return;
            var overtime_entry = res.summary.overtime.find(function (val) {
                return val.company.organization == _this.orga_id && (!_this.company_id || val.company.id == _this.company_id);
            });
            if (overtime_entry)
                _this.overtime = overtime_entry.value;
            var vacation_entry = res.summary.vacation_left.find(function (val) {
                return val.company.organization == _this.orga_id && (!_this.company_id || val.company.id == _this.company_id);
            });
            if (vacation_entry)
                _this.vacation_left = vacation_entry.value;
        });
        // Get Organizations
        this.os.getOrganizationsOfCurrentAccountObservable().subscribe(function (res) {
            if (!res)
                return;
            _this.orgas = res;
            _this.orga = _this.orgas.find(function (orga) { return orga.id == _this.orga_id; });
        });
        // Type Change
        this.form.get('type').valueChanges.subscribe(function (val) {
            if (val == 'free' || val == 'not_available') {
                _this.form.get('start_time').setValidators([Validators.required]);
                _this.form.get('end_time').setValidators([Validators.required]);
            }
            else {
                _this.form.get('start_time').clearValidators();
                _this.form.get('end_time').clearValidators();
            }
            _this.form.get('start_time').updateValueAndValidity();
            _this.form.get('end_time').updateValueAndValidity();
            if (!_this.form.valid) {
                _this.amount_initialised = false;
                _this.form.patchValue({ amount: null });
            }
        });
        // Start Change
        this.form.get('start_date').valueChanges.subscribe(function (val) {
            if (_this.form.value.type == 'free' && val && !_this.form.value.end_date) {
                _this.form.patchValue({ end_date: val });
            }
            // Make sure start <= end
            if (val > _this.form.get('end_date').value)
                _this.form.patchValue({ end_date: val });
        });
        // // End Change
        // this.form.get('end_date').valueChanges.subscribe(val => {
        // });
        // this.form.get('amount').valueChanges.subscribe(val => {
        // 	console.log(val);
        // });
    };
    VacationRequestNewComponent.prototype.getDateStrings = function () {
        var date_from = formatDate(this.form.value.start_date, 'yyyy-MM-dd', this.locale);
        var date_to = formatDate(this.form.value.end_date, 'yyyy-MM-dd', this.locale);
        if (this.form.value.type == 'free' || this.form.value.type == 'not_available') {
            if (this.form.value.start_time)
                date_from += ' ' + this.form.value.start_time;
            if (this.form.value.end_time)
                date_to += ' ' + this.form.value.end_time;
        }
        return [date_from, date_to];
    };
    VacationRequestNewComponent.prototype.onFormChange = function () {
        var _this = this;
        if (!this.form.valid)
            return;
        // Reset Conflicts, Locks
        this.conflicts = [];
        this.locks = [];
        var date_strings = this.getDateStrings();
        if (date_strings[0] > date_strings[1])
            return;
        // check for min date
        var min_date = new Date();
        min_date.setFullYear(min_date.getFullYear() - 1);
        if (date_strings[0] < formatDate(min_date, 'yyyy-MM-dd', this.locale))
            return;
        this.loading = true;
        this.vrs.getInfosInPeriodOfType(this.orga_id, date_strings[0], date_strings[1], this.form.value.type, this.company_id).subscribe(function (res) {
            _this.loading = false;
            if (!res)
                return;
            _this.amount_initialised = true;
            _this.form.patchValue({ amount: res.amount });
            // Change amount type
            if (_this.form.value.type == 'free' || _this.form.value.type == 'not_available')
                _this.amount_type = 'Stunde' + (res.amount != 1 ? 'n' : '');
            else
                _this.amount_type = 'Tag' + (res.amount != 1 ? 'e' : '');
            // Conflicts, Locks, Overlaps
            _this.conflicts = res.conflicts;
            _this.locks = res.locks;
        });
    };
    VacationRequestNewComponent.prototype.onSubmit = function () {
        var _this = this;
        if (!this.form.valid)
            return this.dialog.open(OkDialogComponent, { data: 'Bitte fülle das Formular korrekt aus.' });
        var date_strings = this.getDateStrings();
        // Date Check
        if (date_strings[0] > date_strings[1])
            return this.dialog.open(OkDialogComponent, { data: 'Das Ende muss größer als der Beginn sein.' });
        // Amount Check
        /* if(!this.form.value.amount)
            return alert('Es muss eine Anzahl an Tagen/Stunden angegeben werden.'); */
        this.submitting = true;
        var data = {
            type: this.form.value.type,
            date_from: date_strings[0],
            date_to: date_strings[1],
            amount: this.form.value.amount,
            note: this.form.value.note,
            company: this.company_id
        };
        this.vrs.create(this.orga_id, data).subscribe(function (res) {
            _this.submitting = false;
            if (!res || !res.id)
                return _this.dialog.open(OkDialogComponent, { data: 'Es ist ein Fehler aufgetreten.' });
            _this.router.navigate(['/vacation-requests/']);
        }, function (err) {
            _this.submitting = false;
            if (err && err.error && err.error.text)
                _this.dialog.open(OkDialogComponent, { data: err.error.text });
        });
    };
    return VacationRequestNewComponent;
}(ComponentInit));
export { VacationRequestNewComponent };
